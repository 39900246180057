//Spotlighter
//Prototype Function, holds methods for showing large versions of example images
//takes id for the trigger element, html for content, and a css selector for the close button within the passed html, as parameters 
//No returns
//No dependencies
//Style these css classes for approprate display:
//.spolighterShade - The shade under the popup
//.spotlighterContent - The content wrapper div
export default class Spotlighter {

    //---PROPERTIES---\\
    
    //css selector for the trigger element(s)
    triggerSelector: string;

    //holds the div that fades the page for a popup or anything else, value set in construct
    shadeDiv: HTMLElement;
    
    //the slass for the shade div
    shadeClass = "spotlighterShade";
    
    //holds the html for the element to be spotlighted
    spotlightedHTML: string;
    
    //The element that holds the content
    contentDiv: HTMLElement;
    
    //The class for the element that holds the content
    contentDivClass = "spotlighterContent";
    
    //the css selector for the close button
    closeSelector: string;
    
    //true when the showing, false otherwise
    isShowing = false;
    
    //the random id prefixed in front of the popup container elements
    guid: string;
    
    //---METHODS---\\
    
    //construct
    //assigns event handlers for the page, sets up elements and other variables
    //no parameters
    //no returns
    constructor(triggerIn: string = null, spotlightedHTMLIn : string = null, closeSelectorIn: string = null) {

        //initial values
        this.triggerSelector = triggerIn;
        this.spotlightedHTML = spotlightedHTMLIn;
        this.closeSelector = closeSelectorIn;

        //make a guid
        this.guid = Spotlighter.generateGuid();
        
        //create the shade div
        this.shadeDiv = document.createElement("div");
        this.shadeDiv.className = this.shadeClass;
        this.shadeDiv.id = this.guid + "_shade";
        this.shadeDiv.addEventListener("click", this.toggle.bind(this));
        
        //create the spotlighted element
        this.contentDiv = document.createElement("div");
        this.contentDiv.className = this.contentDivClass;
        this.contentDiv.id = this.guid + "_content";
        this.contentDiv.innerHTML = this.spotlightedHTML;
        
        if(this.triggerSelector !== null) {
            //go though all triggers and assign toggle as the listener
            const triggers: NodeListOf<Element> = document.querySelectorAll(this.triggerSelector);
            triggers.forEach(trigger => trigger.addEventListener("click", this.toggle.bind(this)));
        }

    }//end construct

    //generateGuid
    //makes a simple random string
    //no parameters
    //retuns a string
    static generateGuid(): string {
        
        const characters = "0123456789-_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let randomString = "";

        for (let i = 0; i < 10; i++) {

            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        
        }

        return randomString;
        
    }//end generateGuid   

    //hide
    //hides the content
    //No Parameters
    //No returns
    hide(): void {

        document.body.removeChild(this.shadeDiv);
        document.body.removeChild(this.contentDiv);
        this.isShowing = false;

    }//end hide

    //show
    //spotlights the content
    //No Parameters
    //No returns
    show() : void {

        document.body.appendChild(this.shadeDiv);
        document.body.appendChild(this.contentDiv);
                
        //assign close as click handler to the close button
        const closers: NodeListOf<Element> = document.querySelectorAll(this.closeSelector);
        
        closers.forEach(closer => closer.addEventListener("click", this.hide.bind(this)));

        this.isShowing = true;
        
    }//end show

    //setContent
    //changes the innerHTML of the content wrapper
    //takes the html element and close selector as parameters
    //No returns
    setContent(htmlIn: string, closeSelectorIn: string = null): void {
        
        //set the object properties
        this.spotlightedHTML = htmlIn;
        this.closeSelector = closeSelectorIn;
        
        //add the html
        this.contentDiv.innerHTML = this.spotlightedHTML;
        
        //assign close as click handler to the close button
        const closers: NodeListOf<Element> = document.querySelectorAll(this.closeSelector);
        
        closers.forEach(closer => closer.addEventListener("click", this.hide.bind(this)));
        
    }//end setContent
    
    //toggle
    //Tooggles the spotlight on or off
    //take the event as a parameter
    //Returns false to prevent default browser action
    toggle(event: Event) : boolean {

        event.preventDefault();
        
        if(this.isShowing) {

            this.hide();

        }
        else {

            this.show();
        }
        
        return false;

    }//end toggle

}//end Spotlighter