import Spotlighter from "../spotlighter/spotlighter";

//SpotlightWork
//Prototype Function, holds methods for showing large versions of example images
//takes the css selector for images to spotlight, and a spotlighter object.
    //style the images with your chosen classes in the stylesheet
//No returns
//requires Spotlighter Prototype definition
export default class SpotlightWork {

    //---PROPERTIES---\\
    
    //classes that controt the style
    selector: string;

    //the spotlighter object
    spotlighter: Spotlighter = new Spotlighter();
    
    //---METHODS---\\
    
    //construct
    //constructor simply assigns event handlers for the page, sets up variables
    //no parameters
    //no returns
    constructor(selectorIn: string) {

        //initial values
        this.selector = selectorIn;

        //get all elements with the passed normal class and assign showExample as the listener
        const examples: NodeListOf<Element> = document.querySelectorAll(this.selector);

        examples.forEach(example => example.addEventListener("click", this.toggleShowExample.bind(this)));
               
    }//end assignHandlers

    //hide
    //returns the image to normal
    //No Parameters
    //No returns
    hide(): void {

        this.spotlighter.hide();
        this.spotlighter.setContent("");

    }//end hide

    //spotlight
    //spotlights the image
    //takes an html element to spotlight at the parameter
    //No returns
    spotlight(targetElement: HTMLElement): void {

        this.spotlighter.setContent(targetElement.outerHTML, "body > div.spotlighterContent > img");
        this.spotlighter.show();

    }//end spotlight

    //toggleShowExample
    //Tooggles the spotlight on or off
    //take the event as a parameter
    //No returns
    toggleShowExample(event: Event): void {

        if(this.spotlighter.isShowing == true) {

            this.hide();

        }
        else {

            this.spotlight(<HTMLElement> event.target);
        }

    }//end toggleShowExample


}//end Spotlighter