import Spotlighter from "../spotlighter/spotlighter";
import LoadingGif from "../../images/layout/loading.gif";

//ContactForm
//Prototype Function, holds methods for opening, closing, validating and submiting the contact form.
//takes the css selector for html elements to act as a trigger for showing the form
//No returns
//Requires the google recaptch script to be included on the page. See https://developers.google.com/recaptcha/docs/display#auto_render
export default class ContactForm {

    //---PROPERTIES---\\

    //the selector that will trigger the form popup
    triggerSelector: string;

    //the html for the form
    formHTML: string;
    
    //spotlighter object
    spotlighter: Spotlighter;
    
    //the xhr for the form submission
    submission: XMLHttpRequest;
    
    //the container for the form
    container: HTMLElement;
    
    //the fields in the form
    nameField: HTMLInputElement;
    emailField: HTMLInputElement;
    messageField: HTMLInputElement;
    captchaField: HTMLInputElement;
    
    //captcha widgetid
    captchaID: number;
    
    //validated data from each field
    name: string;
    email: string;
    message: string;
    captcha: string;
    
    //true if captcha has already been rendered
    alreadyRendered: boolean;

    //---METHODS---\\

    //construct
    //basically a constructor simply assigns event handlers for the page, sets up variables
    //no parameters
    //no returns
    constructor(triggerSelectorIn: string) {
        
        //initial values
        this.triggerSelector = triggerSelectorIn;
        this.spotlighter = new Spotlighter(null, null, ".close");
        
        //the html for the form
        this.formHTML = "<div class=\"close\">X</div>"+
                        "<h1>Send Me a Message</h1>"+
                        "<div id=\"" + this.spotlighter.guid + "_contactContainer\" class=\"contactContainer\">" +
                            "<form id=\"" + this.spotlighter.guid + "_contact\" action=\"#\" method=\"post\">" +
                                "\n" +
                                "<!--Name field-->" +
                                "<div class=\"inputContainer\">" +
                                    "<label id=\"" + this.spotlighter.guid + "_contactNameLabel\" for=\"contactName\">Name:</label> <input required=\"required\" id=\"" + this.spotlighter.guid + "_contactName\" name=\"contactName\" type=\"text\" placeholder=\"Enter name here\" />" +
                                    "<span class=\"formFeedback\" id=\"" + this.spotlighter.guid + "_contactNameFeedback\"></span>" +
                                "</div>" +
                                "\n" +
                                "<!--Email field-->" +
                                "<div class=\"inputContainer\">" +
                                    "<label id=\"contactEmailLabel\" for=\"contactEmail\">Email:</label> <input required=\"required\" id=\"" + this.spotlighter.guid + "_contactEmail\" name=\"contactEmail\" type=\"email\" placeholder=\"Enter your email here\" />" +
                                    "<span class=\"formFeedback\" id=\"" + this.spotlighter.guid + "_contactEmailFeedback\"></span>" +
                                "</div>" +
                                "\n" +
                                "<!--Message field-->" +
                                "<div class=\"textAreaContainer\">" +
                                    "<label id=\"contactMessageLabel\" for=\"contactMessage\">Message:</label> <textarea required=\"required\" id=\"" + this.spotlighter.guid + "_contactMessage\" name=\"contactMessage\" ></textarea>" +
                                    "<span class=\"formFeedback\" id=\"" + this.spotlighter.guid + "_contactMessageFeedback\"></span>" +
                                "</div>" +
                                "\n" +
                                "<!--Recaptcha-->" +
                                "\n" +
                                "<div id=\"" + this.spotlighter.guid + "_recaptcha\" class=\"g-recaptcha\"></div>" +
                                "<span class=\"formFeedback\" id=\"" + this.spotlighter.guid + "_recaptchaFeedback\" class=\"g-recaptcha\"></span>" +
                                "\n" +
                                "<!--Submit Button-->" +
                                "<input type=\"submit\" id=\"" + this.spotlighter.guid + "_contactSubmit\" name=\"contactSubmit\" value=\"Send Message\" />" +
                            "</form>" +
                        "</div>";
        
        this.alreadyRendered = false;
        
        const triggers = document.querySelectorAll(this.triggerSelector);
        
        for(let i = 0; i < triggers.length; i++){
            
            triggers[i].addEventListener("click", this.toggle.bind(this));
            
        }
        
    }//end construct
    
    //render
    //sets/resets the form
    //no parameters
    //no returns
    render(): void {

        this.spotlighter.setContent(this.formHTML, ".close"); 
        
        this.captchaID = grecaptcha.render(this.spotlighter.guid + "_recaptcha", {"sitekey": "6Lf84NkSAAAAAJHwh51M1GSETRQmsibSNsyWS6H0", "theme": "dark"});
        
        document.getElementById(this.spotlighter.guid + "_contact").addEventListener("submit", this.submit.bind(this));

        this.container = document.getElementById(this.spotlighter.guid + "_contactContainer");
        
        this.nameField = <HTMLInputElement> document.getElementById(this.spotlighter.guid + "_contactName");
        this.emailField = <HTMLInputElement> document.getElementById(this.spotlighter.guid + "_contactEmail");
        this.messageField = <HTMLInputElement> document.getElementById(this.spotlighter.guid + "_contactMessage");
        this.captchaField = <HTMLInputElement> document.getElementById(this.spotlighter.guid + "_recaptcha");

        this.nameField.addEventListener("blur", this.checkName.bind(this));
        this.emailField.addEventListener("blur", this.checkEmail.bind(this));
        this.messageField.addEventListener("blur", this.checkMessage.bind(this));
        this.captchaField.addEventListener("blur", this.checkCaptcha.bind(this));

        this.alreadyRendered = true;
        
    }//end reset
    
    //hide
    //hides the form
    //No Parameters
    //No returns
    hide(): void {

        this.spotlighter.hide();

    }//end hide

    //show
    //shows the form
    //No Parameters
    //No returns
    show(): void {

        this.spotlighter.show();
        
        if(!this.alreadyRendered) {
            
            this.render();
        }
        
    }//end show
    
    //toggle
    //Tooggles the form on or off
    //take the event as a parameter
    //No returns
    toggle(): void {

        if(this.spotlighter.isShowing == true) {

            this.hide();

        } else {

            this.show();
        }

    }//end toggle
    
    //submit
    //validates and submits the form
    //take the event as a parameter
    //returns false to stop browser submission
    submit(event: Event): boolean {

        event.preventDefault();
        
        const valid = this.validate();
        
        if(valid) {
            
            this.container.innerHTML = `<img class="loading" src="${LoadingGif}">`;
            
            this.submission = new XMLHttpRequest();
            this.submission.addEventListener("readystatechange", this.submissionResult.bind(this));
            this.submission.open("POST", "/api/contact", true);
            this.submission.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            this.submission.send("contactName=" + encodeURIComponent(this.name) + 
                                 "&contactEmail=" + encodeURIComponent(this.email) + 
                                 "&contactMessage=" + encodeURIComponent(this.message) + 
                                 "&g-recaptcha-response=" + encodeURIComponent(this.captcha));
            
        }
        
        return false;

    }//end submit
    
    //submissionResult
    //handles the server response to the submission
    //No parameters
    //No returns
    submissionResult(): void {
        
        if(this.submission.readyState == 4) {
            
            if(this.submission.status == 200) {
            
                const response = JSON.parse(this.submission.response);
                
                if(response.success){
                
                    this.container.innerHTML = "<span class=\"success emphasis\">Message Sent!</span><button class=\"contactReset\">Send Another</button></div>";
            
                } else {
                    
                    this.container.innerHTML = "<span class=\"error emphasis\">An error occured while submitting the form.</span><button class=\"contactReset\">Try Again?</button></div>";
                }
                
            } else {
            
                this.container.innerHTML = "<span class=\"error emphasis\">An error occured while submitting the form.</span><button class=\"contactReset\">Try Again?</button></div>";
            
            }
        
            document.querySelector(".contactReset").addEventListener("click", this.render.bind(this));
            this.alreadyRendered = false;
            
        }
        
    }//end submissionResult
 
    //checkName
    //validates the name field, 
    //Takes no paramaters
    //Returns true/false
    checkName(): boolean {
        
        //Get the form data	
        this.name = this.nameField.value;
        
        //Test the name field, for this test we are just testing that its not blank
        if(this.name != ""){
            
            //passed the test clear any errors in the label
            document.getElementById(this.spotlighter.guid + "_contactNameFeedback").innerHTML = "";
            this.nameField.classList.add("success");
            this.nameField.classList.remove("error");
            return true;
            
        } else {
            
            //failed the test, mark an error in the label and set goodData to false
            document.getElementById(this.spotlighter.guid + "_contactNameFeedback").innerHTML = "<span class=\"error\">Please enter a name.</span>";
            this.nameField.classList.add("error");
            this.nameField.classList.remove("success");
            return false;
        }
        
    }//end checkName
    
    //checkEmail
    //validates the email field, 
    //Takes no paramaters
    //Returns true/false
    checkEmail(): boolean {
        
        //Get the form data	
        this.email = this.emailField.value;
        
        //Test the email
        //Regular expression to test the email against
        // eslint-disable-next-line no-useless-escape
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if(reg.test(this.email)){
            
            //passed the test, clear any error message in the label
            document.getElementById(this.spotlighter.guid + "_contactEmailFeedback").innerHTML = "";
            this.emailField.classList.add("success");
            this.emailField.classList.remove("error");
            return true;
            
        } else {
            
            //failed the test
            document.getElementById(this.spotlighter.guid + "_contactEmailFeedback").innerHTML = "<span class=\"error\">Please enter a valid email.</span>";
            this.emailField.classList.add("error");
            this.emailField.classList.remove("success");
            return false;
          
        }

    }//end checkEmail
    
    //checkMessage
    //validates the message field, 
    //Takes no paramaters
    //Returns true/false
    checkMessage(): boolean {
        
        //Get the form data	
        this.message = this.messageField.value;
        
        //Test the message field, for this test we are just testing that its not blank
        if(this.message != "") {
            
            //passed the test clear any errors in the label
            document.getElementById(this.spotlighter.guid + "_contactMessageFeedback").innerHTML = "";
            this.messageField.classList.add("success");
            this.messageField.classList.remove("error");
            return true;
            
        } else {
            
            //failed the test, mark an error in the label and set goodData to false
            document.getElementById(this.spotlighter.guid + "_contactMessageFeedback").innerHTML = "<span class=\"error\">Please enter a message.</span>";
            this.messageField.classList.add("error");
            this.messageField.classList.remove("success");
            return false;
            
        }

    }//end checkMessage
    
    //checkCaptcha
    //validates the captcha was checked, 
    //Takes no paramaters
    //Returns true/false
    checkCaptcha(): boolean {
        
        this.captcha = grecaptcha.getResponse(this.captchaID);
        
        if(this.captcha != ""){

            document.getElementById(this.spotlighter.guid + "_recaptchaFeedback").innerHTML = "";
            return true;

        } else {

            document.getElementById(this.spotlighter.guid + "_recaptchaFeedback").innerHTML = "<span class=\"error\">Please indicate that you are not a robot.</span>";
            return false;

        }

    }//end checkCaptcha
    
    //validate 
    //Gets form data and attempts to validate, if succesfull it allows submission, 
    //otherwise it prevents submission and shows errors on the page
    //Takes no paramaters
    //Returns true/false
    validate(): boolean {

        //This flag will be used to tell if the form data is good it will be initially be set to true, if it fails a test it will be set to false
        let goodData = true;
        
        //call all the various functions for validating data if any return false set goodData to false
        if(!this.checkName()) {

            goodData = false;

        }

        if(!this.checkEmail()) {

            goodData = false;

        }

        if(!this.checkMessage()) {

            goodData = false;

        }

        if(!this.checkCaptcha()) {

            goodData = false;

        }
        
        return goodData;

    }// end Validate

}//end contactform