import SpotlightWork from "./assets/scripts/spotlight-work/spotlight-work";
import ContactForm from "./assets/scripts/contact-form/contact-form";
import "@styles/style.scss";

//main
//The main code block of the app. Instantiates the app when the load event is fired
//No parameters
//No returns
//No side effects
function main() {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const workSpotter: SpotlightWork = new SpotlightWork(".example");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const contactForm: ContactForm = new ContactForm(".email");

}//End main

//Instantiate App when window has finished loading
window.addEventListener("load", main);